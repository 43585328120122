<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style>
body,
h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
  touch-action: manipulation;
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
.page-container {
  padding-bottom: 112px;
  overflow-y: scroll;
}
</style>

<style lang="scss">
/* vant 组件 css variables 定制化*/
:root {
  /** toast 样式 */
  --van-toast-max-width: calc(100vw - 72px);
  --van-toast-font-size: 16px;
  --van-toast-line-height: 22px;
  --van-toast-text-padding: 11px 16px;
  --van-toast-text-min-width: 64px;
  --van-toast-default-min-height: 64px;
  --van-toast-border-radius: 4px;

  /** dialog 样式 */
  --van-dialog-font-size: 17px;
  --van-dialog-has-title-message-padding-top: 12px;
  --van-dialog-message-font-size: 16px;
  --van-dialog-message-line-height: 22px;
  --van-dialog-border-radius: 8px;
  --van-dialog-confirm-button-text-color: #fff;
  --van-button-default-font-size: 17px;
  --van-button-border-radius: 4px;
  --van-dialog-message-padding: 20px;
  --van-dialog-button-height: 44px;
  --van-dialog-has-title-message-text-color: #848484;
  --van-dialog-header-isolated-padding: 32px 0 20px 0;
}

.van-toast {
  text-align: left;
}

.van-toast--text {
  width: max-content;
}

.van-dialog__header {
  color: #222;
  font-weight: bold;
}

.van-dialog__footer {
  justify-content: center;
  padding: 0 20px 20px 20px;
}

.van-dialog__message {
  padding-bottom: 20px;
  color: #848484;
}

.van-dialog__confirm {
  background-color: #0093ff;
  font-weight: bold;
}

.van-dialog__cancel {
  border: solid 1px #dedede;
}

.van-hairline--top:after {
  border-width: 0;
}

.van-hairline--left {
  margin-left: 12px;
}

.van-dialog__content--isolated {
  min-height: auto;
  padding-top: 12px;
}
</style>
